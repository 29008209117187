<template>
    <div class="daybook" style="">
      <div style="margin-left: 241px;padding-top: 24px;margin-bottom: 30px;">
        <img src="../assets/daybook/6.png" alt="">
      </div>
      <img style="" src="../assets/daybook/4.png" alt="">
      <div style="margin: 20px 240px 0;">
        <div style="display: flex;justify-content: space-between;width: 1438px;">
          <img src="../assets/daybook/1.png" alt="">
          <img src="../assets/daybook/3.png" alt="">
          <img src="../assets/daybook/2.png" alt="">
        </div>
        <img style="margin: 20px 0;height: 80px;width: 1438px;" src="../assets/daybook/5.png" alt="">
        <div style="background-color:#FFFFFF;width: 1438px;">
          <div style="display: flex;width: 100%;">
            <div style="width: 180px;height: 180px;margin: 20px 24px;background-color: blue;"></div>
            <div style="font-family: Microsoft YaHei;font-weight: 400;display: flex;justify-content: center;flex-direction: column;width: 1438px;">
              <div style="font-size: 18px;color: #2E2E2E;">慢作 复古牛皮纸笔记本 A5 256页</div>
              <div style="font-size: 18px;color: #FF0520;margin-top: 14px;margin-bottom: 23px;">1.86元 （需买 3件，共5.59元）</div>
              <div style="font-size: 14px;color: #7E7F7F;">天猫目前售价4.9元，下单3件，参加满1件打8.4折活动，叠加立减12%活动，领取满13元减5元优惠券，实付5.59元，合1.</div>
              <div style="margin-top: 36px;display: flex;justify-content: space-between;align-items: center;padding-right: 95px;">
                <div style="display: flex;">
                  <div>16525</div>
                  <div>16</div>
                  <div>天猫 | 07月12日 </div>
                </div>
                <div @click="Go" style="text-align: center;width: 88px;height: 32px;background: #E9390D;border-radius: 16px;font-size: 12px;color: #FFFFFF;line-height: 32px;">
                  去领券
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="show" class="show">
        <div style="display: flex;margin-bottom: 235px;">
          <div style="display: flex;flex-direction: column;align-items:center;">
            <img src="../assets/popup/2.png" alt="">
            <div style="width: 168px;height: 168px;background-color: #E9390D;margin: 47px 0 83px;"></div>
            <div style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 300;color: #F6F7FD;">扫码下载佑省购物APP</div>
          </div>
          <div style="display: flex;flex-direction: column;margin-left: 102px;align-items: center;justify-content: center;">
            <img src="../assets/popup/3.png" alt="">
            <div style="width: 168px;height: 168px;background-color: #E9390D;margin: 47px 0 83px;"></div>
            <div style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 300;color: #F6F7FD;">扫码下载佑省购物APP</div>
          </div>
        </div>
        <div @click="show=false" ><img style="width: 85px;height: 85px;" src="../assets/popup/1.png" alt=""></div>
      </div>
    </div>
</template>
<script>
export default {
  // name: 'daybook',
  data(){
    return {
      show:false,
    }
  },
  methods:{
    Go(){
      this.show = true
    }
  },
}
</script>
<style>
*{
  margin: 0;
}
.daybook{
  position: relative;
  background: #F6F7FD;width:100vw;
}
.show{
  position: absolute;
  top: 0;
  width: 1920px;
  height: 100%;
  background: #000000;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
  