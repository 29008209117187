<template>
    <div class="head" style="overflow-y: hidden;overflow-x: scroll;">
      <div class="image">
        <img :src="tabNub==0||tabNub==1?require('../assets/logo.png'):require('../assets/logo2.png')" alt="">
      </div>
      <div v-if="tabNub==3||tabNub==2?'true':''" style="height: 93px;width: 100%;background-color: #FFFFFF;"></div>
      <div :class="tabNub==0||tabNub==1?'headRight':'newHeadRight'">
        <div class="text" v-for="(item,index) in list" :key="index" :style="{color:tabNub==index||tab==index?'#EE0009': tabNub==3||tabNub==2?' #000000':'#FFFFFF'}" @mouseenter="mouseenter(index)" @mouseleave="mouseleaveA(index)">
          <div style="white-space: nowrap" @click="onTab(index)">
            {{ item.text }}
          </div>
          <div>
            <div class="lineae" v-if="tabNub==index||tab==index"></div>
          </div>
          <div class="appText" v-if="tab==1&show" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(1)">
            <div :style="{color:clickNub==0?'#EE0009':'#FFFFFF'}" @click="clickNub=0,tabNub=1">佑省购物APP</div>
            <div :style="{color:clickNub==1?'#EE0009':'#FFFFFF'}" style="margin-top: 24px;" @click="clickNub=1,tabNub=1">佑省记账APP</div>
          </div>
        </div>
        <div class="appTextB" v-if="tab==3&showB" @mouseenter="mouseenter(3)" @mouseleave="mouseleave(3)">
          <div style="margin-left: 500px;">
            <div>商务合作</div>
            <div style="margin-top: 24px;">提交资料</div>
          </div>
        </div>
      </div>
      <div v-show="tabNub==1||tabNub==0">
        <img v-show="clickNub!=1" style="" src="../assets/0.png" alt=""> 
        <div v-show="tabNub==0">
          <img src="../assets/2.png" alt="">
          <img src="../assets/1.png" alt="">
          <div class="moreRed" @click="goDaybook"></div>
          <div class="moreGreen" @click="goGood"></div>
        </div>
        <div v-show="tabNub==1&clickNub==0">
          <img src="../assets/shop3.png" alt="">
          <img src="../assets/shop1.png" alt="">
          <img src="../assets/shop2.png" alt="">
        </div>
        <div v-show="tabNub==1&clickNub==1">
          <img src="../assets/daybook2.png" alt="">
          <img src="../assets/daybook1.png" alt="">
          <img src="../assets/daybook3.png" alt="">
        </div>
      </div>
      <div v-show="tabNub==2">
        <img src="../assets/about1.png" alt="">
        <img src="../assets/about2.png" alt="">
        <img src="../assets/about3.png" alt="">
      </div>
      <div v-show="tabNub==3" class="tabNub">
        <div class="tabNub3">
          <img src="../assets/contact.png" alt="">
          <div class="tabNub3_div">
            <div>招商联系：</div>
            <div>18520403124（微信同号）</div>
            <div>18520403124（微信同号）</div>
          </div>
        </div>
        <div class="material">
            <div class="material_text">提交资料</div>
            <div class="letter">Company introduction</div>
            <div class="input">
              <div style="position: relative;">
                <input type="text" placeholder="联系人">
                <img src="../assets/contacts.png" alt="">
              </div>
              <div style="position: relative;">
                <input type="text" placeholder="所在城市">
                <img src="../assets/city.png" alt="">
              </div>
              <div style="position: relative;">
                <input type="text" placeholder="邮箱">
                <img src="../assets/mailbox.png" alt="">
              </div>
              <div style="position: relative;">
                <input type="text" placeholder="联系人电话">
                <img src="../assets/phone.png" alt="">
              </div>
            </div>
            <div class="submit">
                立即提交
              </div>
          </div>
      </div>
      <div style="display: flex;flex-direction: column;width: 100vw;">
          <div class="contactTop">
            <div class="interestText">
              <span>关注我们</span>
              <img src="../assets/wx.png" alt="">
              <img src="../assets/qq.png" alt="">
            </div>
            <div style="height: 1px;background-color: #D3D3D3;width: 100%;"></div>
            <div style="display: flex;justify-content: space-between;margin:  31px 102px 170px;">
              <div class="titleList">
                <div>产品</div>
                <div>佑省购物APP</div>
                <div>佑省记账APP</div>
              </div>
              <div class="titleList">
                <div>关于佑省</div>
                <div>企业介绍</div>
                <div>成长足迹</div>
              </div>
              <div class="titleList">
                <div>联系我们</div>
                <div>商务合作</div>
                <div>提交资料</div>
              </div>
              <div class="titleList">
                <div>下载</div>
                <div>佑省购物APP下载</div>
                <div>佑省记账APP下载</div>
              </div>
            </div>
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;font-size: 14px;font-family: Microsoft YaHei;font-weight: 300;color: #D3D3D3;">
              <div>温馨提示: 建议您使用IE9及以上版本的浏览器,其他浏览器对系统兼容性不完善!</div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div style="height: 60px;font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;background-color: #000000;line-height: 60px;text-align: center;">
            月洋沧澜 2022-至今© All Rights Reserved | 杭州月洋沧澜科技有限公司 | <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #FFFFFF;">浙ICP备2022018568号</a>
          </div>
        </div>
        <div v-show="showPop" class="show">
          <div style="display: flex;margin-bottom: 235px;">
            <div style="display: flex;flex-direction: column;align-items:center;">
              <img src="../assets/popup/2.png" alt="">
              <div style="width: 168px;height: 168px;background-color: #E9390D;margin: 47px 0 83px;"></div>
              <div style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 300;color: #F6F7FD;">扫码下载佑省购物APP</div>
            </div>
            <div style="display: flex;flex-direction: column;margin-left: 102px;align-items: center;justify-content: center;">
              <img src="../assets/popup/3.png" alt="">
              <div style="width: 168px;height: 168px;background-color: #E9390D;margin: 47px 0 83px;"></div>
              <div style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 300;color: #F6F7FD;">扫码下载佑省购物APP</div>
            </div>
          </div>
          <div @click="showPop=false" ><img style="width: 85px;height: 85px;" src="../assets/popup/1.png" alt=""></div>
        </div>
    </div>
  
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      showPop:false,
      tabNub: 0,
      show:false,
      tab:0,
      clickNub:2,
      showB:false,
      list:[
      {text:'首页'},
      {text:'产品'},
      {text:'关于佑省'},
      {text:'联系我们'},
      {text:'下载'},
      ]
    };
  },
  methods:{
    onTab(index){
      if (index==4) {
        this.showPop =true
      }
      if(index!=4){
        if(index==1){
          this.tabNub = index
          this.clickNub = 0
          return
        }
        this.tabNub = index
        this.clickNub = 3
      }
    },
    Go(){
      this.showPop = true
    },
    goDaybook(){
      this.$router.push('/about')
    },
    goGood(){
      this.$router.push('/daybookView')
    },
    mouseenter(index){
      if(index == 4){
        return
      }
      this.tab = index
      if(index == 1){
        this.show =true
      }
      if(index == 3){
        this.showB =true
      }
    },
    mouseleave(index){
      if(index == 4){
        return
      }
      this.tab = this.tabNub
      if(index == 1){
        this.show =false
        return
      }
      if(index == 3){
        console.log(333333);
        this.showB =false
        return
      }
    },
    mouseleaveA(index){
      console.log(index);
      if(index==0||index==2){
        this.tab = this.tabNub
      }
    }
  },
};
</script>

<style scoped>
.show{
  position: absolute;
  top: -1100px;
  width: 1920px;
  /* height: 1773px; */
  background: #000000;
  opacity: 0.8;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}
  /* 定位点击 */
  .moreGreen{
    position: absolute;
    height: 30px;
    width: 133px; 
    left: 285px;
    top: 1468px;
    cursor:pointer;
    /* background-color: #000012; */
  }
  .moreRed{
    position: absolute;
    height: 30px;
    width: 133px; 
    right: 268px;
    top: 2272px;
    cursor:pointer;
    /* background-color: #000012; */
  }
  .head{
    position: relative;
    width: 1920px;
  }
  .newHeadRight{
    display: flex;
    position: absolute;
    right: 12.24%;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    top: 36px;
    color: #FFFFFF;
    height: 93px;
  }
  .newHeadRight .text {
    cursor:pointer;
  }
  .headRight{
    display: flex;
    position: absolute;
    right: 12.24%;
    color: #FFFFFF;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    top: 36px;
  }
  .headRight .text {
    cursor:pointer;
  }
  .appText{
    position: absolute;
    top: 50px;
    left: 110px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    /* background-color: #fff; */
  }
  .appTextB{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 150px;
    top: 50px;
    padding: 0 848px 0 910px;
    left: -990px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    white-space: nowrap;
    font-weight: 300;
    transition: all 1.5s;
    color: #252525;
    background-color: #FFFFFF;
  }
  .appTextB{
    cursor:pointer
  }
  .lineae{
    /* width: 50px; */
    margin-top: 10px;
    height: 2px;
    background: #EE0009;
  }
  .head .headRight .text {
    margin-right:79px;
  }
  .head .newHeadRight .text {
    margin-right:79px;
  }
  .head .image{
      position: absolute;
      left: 12.24%;
      top: 31px;
    }
  .contactTop{
    background: #1B1F25;
    padding: 85px 12.604% 33px;
  }
  .interestText{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
  .interestText img {
    width: 44px;
    height: 44px;
  }
  .interestText :first-child {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #F9F9F9;
   }
  .interestText :nth-child(2){
    margin: 0 17px 0 35px;
  }
  .titleList :first-child{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #F9F9F9;
  }
  .titleList :nth-child(2){
    margin: 20px 0;
  }
  .titleList :not(:first-child){
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #D3D3D3;
  }
  /* .tabNub3{
    position: relative;
  }
  .tabNub3 img {
    position: absolute;
  } */
  .material{
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .material .input{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .material .input img{
    position: absolute;
    left: 35px;
    top: 17px;
  }
  .material .input input {
    margin-bottom: 72px;
    padding-left: 104px;
    font-size: 16px;
    color: #242A31;
    background: none;
    outline:none;
    width: 660px;
    height: 78px;
    border: 1px solid #000012 !important;
    border-radius: 39px;
  }
  .material .input input :focus {
    border: none;
  }
  .material .material_text{
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2C3E4E;
  }
   .material .letter{
    font-size: 16px;
    font-family: Kozuka Gothic Pr6N;
    font-weight: normal;
    color: #999999;
    padding: 13px 0 96px 0;
  }
  .tabNub3 .tabNub3_div{
    position: absolute;
    /* width: 100%; */
    display: flex;
    /* justify-content:space-around; */
    align-items: center;
    margin-left: 324px;
    top: 30%;
    z-index: 100;
  }
  .tabNub3 .tabNub3_div :first-child{
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #0B0B23;
    margin-left: 75px;
  }
  .tabNub3 .tabNub3_div :nth-child(2) {
    margin-right: 141px;
  }
  .tabNub3 .tabNub3_div :not(:first-child) {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #0B0B23;
  }
  .submit{
    margin-top: 18px;
    margin-bottom: 223px;
    width: 319px;
    height: 43px;
    line-height: 43px;
    text-align: center;
    background: #E60012;
    border-radius: 21px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }

</style>
